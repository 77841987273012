<template>
  <div>     
    <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">申请借支成功</div>
    </div>
  </nav-bar-back>
        <div class="myheader">
            <img src='~assets/images/common/success.png' />
            <p>恭喜您,借支申请提交成功。</p>
        </div>
 <div class="myinfoContent" >  
<div class="info" v-if="res"> 
    <van-cell class="cell" >
        <div class="item">
  <p>申请时间</p>
  <p>{{res.created_at}}</p>
        </div>
    </van-cell>
    <van-cell  class="cell"  :to="{ path: '/Audit', query: { id: 1 }}" >
        <div class="item">
  <p>到账金额</p>
  <p>{{res.money}}元</p>
        </div>
    </van-cell>
<van-cell class="cell"  v-if="res.weixin">
        <div class="item">
  <p>到账微信号</p>
  <p>{{res.weixin}}</p>
        </div>
    </van-cell>
    <van-cell class="cell"  v-if="res.bank_name">
        <div class="item">
  <p>到账银行</p>
  <p>{{res.bank_name}}</p>
        </div>
    </van-cell>
    <van-cell class="cell"  v-if="res.bank_cart" >
        <div class="item">
  <p>到账卡号</p>
  <p >{{res.bank_cart}}</p>
        </div>
    </van-cell>
</div>
    <van-button  class="apply" @click="detail">查看借支详情</van-button>
<van-button  class="apply2" @click="home">返回首页</van-button>
        </div>

<!--  -->
  </div>
</template>
<script>
import { Cell, CellGroup,Field,Button } from 'vant';
import NavBarBack from 'common/navbar/NavBarBack'
import {detail} from "network/apply";


export default {
  components: {
      NavBarBack,
       [Cell.name]:Cell,
       [CellGroup.name]:CellGroup,
       [Field.name]:Field,
       [Button.name]:Button
  },
  created () {
     this.type=this.$route.query.type 
      this.res=this.$route.query
     console.log(this.$route.query,'result')
    
  },
  methods: {
   detail(){
    //    detail(this.res.id).then(res=>{
    //        console.log(res)
    //    })
                   this.$router.push({path:'/myBorrowDetail',query:{id:this.res.id}})
       
    //  this.$router.push('/myBorrowDetail')
  },
  home(){
 this.$router.push('/profile')
  }
    
  },
  data () {
      return {
          time:'2019/11/10',
          idCard:'44173229898989875', 
          bankName:'中国工商银行',
          bankCode:'400028 22980 3323 2234',
          money:'100',
          type:'',
          wx:'',
          res:''

      }
  }
}
</script>

<style scoped>
.apply{
     width: 93%;
    margin: 30px auto 0px auto;
    border-radius: 5px;
    color: #fff;
     background-image: linear-gradient(to right, rgb(243, 70, 31) , rgb(243, 40, 71));
    /* background-color: #f00 */
}
.apply2{
     width: 93%;
    margin: 18px auto 0px auto;
    border-radius: 5px;
    color: #333;
    background-color: #fff
}
.fields{
    background-color: #fff;
      width: 93%;
      border-radius: 4px;
      margin: 0 auto 0 auto;

}
.field{
    display: flex;
    align-items: center;
    /* width: 94%; */
    background-color: transparent;
    
}
.myheader{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.myheader img{
    margin-bottom: 20px;
    width: 65px;
    height: 65px;
}
.myheader  p{
    font-size: 15px;
margin-bottom: 10px;
    font-weight: 400;
}
.money{
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 20px
}
.selected{
    font-size: 14px;
    border: none;
    color: hsl(0, 0%, 94%);
    width: 27%;
    height: 35px;
    border-radius: 5px;
       box-shadow: 0px 1px 1px #ccc;

    background-color: rgb(250, 115, 27)
}
.unSelected{
    border: none;
    color: #333;
    width: 27%;
    font-size: 14px;

    height: 35px;
    border-radius: 5px;
    box-shadow: 0px 1px 1px #ccc;
    background-color: #fff
}
.des{
    margin-left: 10px;
    font-size: 14px;
}
.myinfoContent{
   width: 100%;
   display: flex;
   flex-direction: column;
}

.info{
    width: 92%;
    padding: 1px;
    
     background-color: white;
     border-radius: 5px;
    margin: 20px auto 0 auto 
}
.item{
    display: flex;
    justify-content: space-between
}
.cell{
    background-color: tranredsparent !important 
    /* margin-top: 10px; */
}
.item img{
    width: 23px;
    height: 23px;;
}
</style>





